import React from 'react';
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import './index.scss'

/*
* @ All pages Import
*/
import HomeOne from './pages/HomeOne'
import Error404 from "./pages/Error404";
import ScrollToTop from "./helpers/ScrollToTop";
import ReactGA from 'react-ga';

ReactGA.initialize('UA-2973684-1');

const App = () => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    return (
        <Router>
                <ScrollToTop>
                    <Switch>
                        <Route exact path={`${process.env.PUBLIC_URL + '/'}`} component={HomeOne}/>
                        <Route exact component={Error404}/>
                    </Switch>
                </ScrollToTop>
        </Router>
    );
};

export default App;