import React from 'react';

function ServiceItem(props) {
    return (
        <div className="col-sm-6 col-lg-4">
            <div className="service-item">
                <figure className="service-thumb">
                    <a href={props.url} title={props.title}>
                        <img src={require('../../assets/img/' + props.thumb)} alt={props.title} width="140" height="140" />
                    </a>

                    <figcaption className="service-txt">
                        <h5>{props.title}</h5>
                    </figcaption>
                </figure>
                <div className="service-content">
                    <div className="service-content-inner">
                        <h5>
                            <a href={props.url} className="stretched-link">{props.title}</a>
                        </h5>
                        <p>{props.text}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ServiceItem;