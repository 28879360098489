import React, {Fragment} from 'react';
import Services from '../components/Services'

const HomeOne = () => {
    return (
        <Fragment>
            <Services classes="sm-top-wt"/>
        </Fragment>
    );
};

export default HomeOne;